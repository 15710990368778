import { AudioLines, Cog, Crosshair, Gamepad2, MonitorStop, Rocket, Settings, TabletSmartphone, Touchpad, Video } from 'lucide-react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

export default function Banner() {
  const [activeBanner, setActiveBanner] = useState('lower-ping');

  const [isHovering, setIsHovering] = useState(false);
  const [, setOtherItemHovered] = useState(false);

  const banners = ['lower-ping', 'boost-fps', 'pro-settings', 'audiopad', 'green-aim', 'replay-x'];
  const { t } = useTranslation('common');

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isHovering) {
        setActiveBanner((prev) => {
          const currentIndex = banners.indexOf(prev);
          const nextIndex = (currentIndex + 1) % banners.length;
          return banners[nextIndex] || '';
        });
      }
    }, 3000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovering]);

  return (
    <div className='hidden h-[597px] min-w-[1000px] select-none flex-col overflow-hidden rounded-3xl border border-zinc-800 p-5 xl:flex' onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <div className='mb-9 flex justify-between'>
        <div className='flex items-center gap-5'>
          <Image src='/assets/images/novo-logo-verde-branco.png' alt='Logo' width={109} height={42} />
          <Image src='/assets/images/banner/notificacao.webp' alt='Notificacao' width={38} height={21} />
        </div>
        <div>
          <Image src='/assets/images/banner/minimizar-fechar.webp' alt='' width={37} height={15} />
        </div>
      </div>

      <div className='flex h-full gap-3'>
        <div className='flex size-full max-h-full max-w-[250px] flex-col justify-between'>
          <div className='flex flex-col'>
            <div className='flex items-center gap-2 rounded-xl bg-[#ADF35C] p-2' onMouseEnter={() => setOtherItemHovered(false)} onMouseLeave={() => setOtherItemHovered(false)}>
              <MonitorStop size={20} className={`text-black'`} />
              <p className='font-bold'>Desktop</p>
            </div>
            <div className='flex items-center gap-2 rounded-md p-2 transition-colors' onMouseEnter={() => setOtherItemHovered(true)} onMouseLeave={() => setOtherItemHovered(false)}>
              <Gamepad2 size={20} className='text-white transition-colors group-hover:text-black' />
              <p className='text-base font-bold text-zinc-300 transition-colors group-hover:text-black'>Console</p>
              <div className='h-6 w-fit rounded-2xl bg-[#ADF35C] px-6 py-0.5'>
                <p className='text-sm font-bold text-black'>{t('banner.coming_soon')}</p>
              </div>
            </div>
            <div className='flex items-center gap-2 rounded-md p-2 transition-colors' onMouseEnter={() => setOtherItemHovered(true)} onMouseLeave={() => setOtherItemHovered(false)}>
              <TabletSmartphone size={20} className='text-white transition-colors group-hover:text-black' />
              <p className='font-bold text-zinc-300 transition-colors group-hover:text-black'>Mobile</p>
            </div>
            <div className='flex items-center gap-2 rounded-md p-2 transition-colors' onMouseEnter={() => setOtherItemHovered(true)} onMouseLeave={() => setOtherItemHovered(false)}>
              <AudioLines size={20} className='text-white transition-colors group-hover:text-black' />
              <p className='font-bold text-zinc-300 transition-colors group-hover:text-black'>Multi Internet</p>
            </div>
            <div className='flex items-center gap-2 rounded-md p-2 transition-colors' onMouseEnter={() => setOtherItemHovered(true)} onMouseLeave={() => setOtherItemHovered(false)}>
              <Rocket size={20} className='text-white transition-colors group-hover:text-black' />
              <p className='font-bold text-zinc-300 transition-colors group-hover:text-black'>Boost FPS</p>
            </div>
            <div className='flex items-center gap-2 rounded-md p-2 transition-colors' onMouseEnter={() => setOtherItemHovered(true)} onMouseLeave={() => setOtherItemHovered(false)}>
              <Crosshair size={20} className='text-white transition-colors group-hover:text-black' />
              <p className='font-bold text-zinc-300 transition-colors group-hover:text-black'>Green Aim</p>
            </div>
            <div className='flex items-center gap-2 rounded-md p-2 transition-colors' onMouseEnter={() => setOtherItemHovered(true)} onMouseLeave={() => setOtherItemHovered(false)}>
              <Cog size={20} className='text-white transition-colors group-hover:text-black' />
              <p className='font-bold text-zinc-300 transition-colors group-hover:text-black'>Pro Settings</p>
            </div>
            <div className='flex items-center gap-2 rounded-md p-2 transition-colors' onMouseEnter={() => setOtherItemHovered(true)} onMouseLeave={() => setOtherItemHovered(false)}>
              <Touchpad size={20} className='text-white transition-colors group-hover:text-black' />
              <p className='font-bold text-zinc-300 transition-colors group-hover:text-black'>Audiopad</p>
            </div>
            <div className='flex items-center gap-2 rounded-md p-2 transition-colors' onMouseEnter={() => setOtherItemHovered(true)} onMouseLeave={() => setOtherItemHovered(false)}>
              <Video size={20} className='text-white transition-colors group-hover:text-black' />
              <p className='font-bold text-zinc-300 transition-colors group-hover:text-black'>Replay X</p>
            </div>
            <div className='flex items-center gap-2 rounded-md p-2 transition-colors' onMouseEnter={() => setOtherItemHovered(true)} onMouseLeave={() => setOtherItemHovered(false)}>
              <Settings size={20} className='text-white transition-colors group-hover:text-black' />
              <p className='font-bold text-zinc-300 transition-colors group-hover:text-black'>Settings</p>
            </div>
          </div>

          <div className='mb-16 flex flex-col gap-4'>
            <div className='flex items-center gap-1'>
              <p className='uppercase text-[#8A8A9A]'>Version 3.0</p>
            </div>
          </div>
        </div>

        <div className='w-full'>
          <div className='flex w-full gap-3 pb-2.5'>
            <div className='flex w-full items-center gap-1 rounded-md border border-[#1D212A] p-2'>
              <Image src='/assets/images/banner/lupa.webp' alt='Lupa' width={13} height={13} />
              <p className='font-bold text-zinc-300'>{t('banner.bars.search')}</p>
            </div>

            <div className='flex w-full max-w-[224px] items-center justify-between gap-1 rounded-md border border-[#1D212A] p-2'>
              <p className='font-bold text-zinc-300'>{t('banner.bars.app')}</p>
              <Image src='/assets/images/banner/seta-baixo.webp' alt='Sete' width={13} height={8} />
            </div>
          </div>

          <div className='h-[292px]'>
            {activeBanner === 'lower-ping' && (
              <div className="relative h-[292px] w-full max-w-[1000px] bg-[url('/assets/images/banner/lower-ping-banner.webp')] bg-contain bg-bottom bg-no-repeat px-11 pt-14">
                <div className='fade-enter flex w-1/2 flex-col gap-2'>
                  <p className='text-3xl font-bold leading-7 text-white'>{t('banner.banners.lower_ping.title')}</p>
                  <p className='leading-2 text-white'>{t('banner.banners.lower_ping.content')}</p>
                </div>
                <Image className='fade-enter-img absolute -right-2 bottom-0' src='/assets/images/banner/lower-ping-img.webp' alt='' width={375} height={678} />
              </div>
            )}

            {activeBanner === 'boost-fps' && (
              <div className="relative h-[292px] w-full max-w-[1000px] bg-[url('/assets/images/banner/boost-fps-banner.webp')] bg-contain bg-bottom bg-no-repeat px-11 pt-14">
                <div className='fade-enter flex w-1/2 flex-col gap-2'>
                  <p className='text-3xl font-bold text-black'>Boost FPS</p>
                  <p className='text-black'>{t('banner.banners.boost_fps.content')}</p>
                </div>
                <Image className='fade-enter-img absolute bottom-0 right-0' src='/assets/images/banner/boost-fps-img.webp' alt='' width={375} height={678} />
              </div>
            )}

            {activeBanner === 'pro-settings' && (
              <div className="relative h-[292px] w-full max-w-[1000px] bg-[url('/assets/images/banner/pro-settings-banner.webp')] bg-contain bg-bottom bg-no-repeat px-11 pt-14">
                <div className='fade-enter flex w-1/2 flex-col gap-2'>
                  <p className='text-3xl font-bold leading-7 text-white'>Pro Settings</p>
                  <p className='leading-2 text-white'>{t('banner.banners.pro_settings.content')}</p>
                </div>
                <Image className='fade-enter-img absolute bottom-0 right-0 h-[440px] w-auto' src='/assets/images/banner/pro-settings-img.webp' alt='' width={450} height={678} />
              </div>
            )}

            {activeBanner === 'audiopad' && (
              <div className="relative h-[292px] w-full max-w-[1000px] bg-[url('/assets/images/banner/audiopad-banner.webp')] bg-contain bg-bottom bg-no-repeat px-11 pt-14">
                <div className='fade-enter flex w-1/3 flex-col gap-2'>
                  <p className='text-3xl font-bold leading-7 text-white'>{t('banner.banners.audiopad.title')}</p>
                  <p className='leading-2 text-white'>{t('banner.banners.audiopad.content')}</p>
                </div>
                <Image className='fade-enter-img absolute -right-6 bottom-0' src='/assets/images/banner/audiopad-img.webp' alt='' width={500} height={678} />
              </div>
            )}

            {activeBanner === 'green-aim' && (
              <div className="relative h-[292px] w-full max-w-[1000px] bg-[url('/assets/images/banner/green-aim-banner.webp')] bg-contain bg-bottom bg-no-repeat px-11 pt-14">
                <div className='fade-enter flex w-1/3 flex-col gap-2'>
                  <p className='text-3xl font-bold leading-7 text-white'>Green Aim</p>
                  <p className='leading-2 text-white'>{t('banner.banners.green_aim.content')}</p>
                </div>
                <Image className='fade-enter-img absolute -right-2.5 bottom-0' src='/assets/images/banner/green-aim-img.webp' alt='' width={450} height={678} />
              </div>
            )}

            {activeBanner === 'replay-x' && (
              <div className="relative h-[292px] w-full max-w-[1000px] bg-[url('/assets/images/banner/replay-x-banner.webp')] bg-contain bg-bottom bg-no-repeat px-11 pt-14">
                <div className='fade-enter flex w-1/3 flex-col gap-2'>
                  <p className='text-3xl font-bold leading-7 text-white'>{t('banner.banners.replay_x.title')}</p>
                  <p className='leading-2 text-white'>{t('banner.banners.replay_x.content')}</p>
                </div>
                <Image className='fade-enter-img absolute -right-5 bottom-0' src='/assets/images/banner/replay-x-img.webp' alt='' width={500} height={678} />
              </div>
            )}
          </div>

          <div className='relative grid grid-cols-3 gap-2 pt-5'>
            <button type='button' className={`flex h-[30px] items-center justify-center gap-2 rounded-md p-2 transition-opacity ${activeBanner === 'lower-ping' ? 'bg-[#ADF35C]' : 'bg-[#363D43] hover:opacity-80'}`} onClick={() => setActiveBanner('lower-ping')}>
              <Image src={activeBanner === 'lower-ping' ? `/assets/images/banner/lower-ping-button-active.webp` : `/assets/images/banner/lower-ping-button.webp`} alt='Imagem do lower-ping' width={9} height={9} />
              <p className={`initial font-bold ${activeBanner === 'lower-ping' ? 'text-black' : 'text-zinc-300'}`}>{t('banner.buttons.lower_ping')}</p>
            </button>

            <button type='button' className={`flex h-[30px] items-center justify-center gap-2 rounded-md p-2 transition-opacity ${activeBanner === 'boost-fps' ? 'bg-[#ADF35C]' : 'bg-[#363D43] hover:opacity-80'}`} onClick={() => setActiveBanner('boost-fps')}>
              <Image src={activeBanner === 'boost-fps' ? `/assets/images/banner/boost-fps-button-active.webp` : `/assets/images/banner/boost-fps-button.webp`} alt='Imagem do boost-fps' width={9} height={9} />
              <p className={`initial font-bold ${activeBanner === 'boost-fps' ? 'text-black' : 'text-zinc-300'}`}>Boost FPS</p>
            </button>

            <button type='button' className={`flex h-[30px] items-center justify-center gap-2 rounded-md p-2 transition-opacity ${activeBanner === 'pro-settings' ? 'bg-[#ADF35C]' : 'bg-[#363D43] hover:opacity-80'}`} onClick={() => setActiveBanner('pro-settings')}>
              <Image src={activeBanner === 'pro-settings' ? `/assets/images/banner/pro-settings-button-active.webp` : `/assets/images/banner/pro-settings-button.webp`} alt='Imagem do pro-settings' width={9} height={9} />
              <p className={`initial font-bold ${activeBanner === 'pro-settings' ? 'text-black' : 'text-zinc-300'}`}>Pro Settings</p>
            </button>

            <button type='button' className={`flex h-[30px] items-center justify-center gap-2 rounded-md p-2 transition-opacity ${activeBanner === 'audiopad' ? 'bg-[#ADF35C]' : 'bg-[#363D43] hover:opacity-80'}`} onClick={() => setActiveBanner('audiopad')}>
              <Image src={activeBanner === 'audiopad' ? `/assets/images/banner/audiopad-button-active.webp` : `/assets/images/banner/audiopad-button.webp`} alt='Imagem do audiopad' width={9} height={9} />
              <p className={`initial font-bold ${activeBanner === 'audiopad' ? 'text-black' : 'text-zinc-300'}`}>{t('banner.buttons.audiopad')}</p>
            </button>

            <button type='button' className={`flex h-[30px] items-center justify-center gap-2 rounded-md p-2 transition-opacity ${activeBanner === 'green-aim' ? 'bg-[#ADF35C]' : 'bg-[#363D43] hover:opacity-80'}`} onClick={() => setActiveBanner('green-aim')}>
              <Image src={activeBanner === 'green-aim' ? `/assets/images/banner/green-aim-button-active.webp` : `/assets/images/banner/green-aim-button.webp`} alt='Imagem do green-aim' width={9} height={9} />
              <p className={`initial font-bold ${activeBanner === 'green-aim' ? 'text-black' : 'text-zinc-300'}`}>Green Aim</p>
            </button>

            <button type='button' className={`flex h-[30px] items-center justify-center gap-2 rounded-md p-2 transition-opacity ${activeBanner === 'replay-x' ? 'bg-[#ADF35C]' : 'bg-[#363D43] hover:opacity-80'}`} onClick={() => setActiveBanner('replay-x')}>
              <Image src={activeBanner === 'replay-x' ? `/assets/images/banner/replay-x-button-active.webp` : `/assets/images/banner/replay-x-button.webp`} alt='Imagem do replay-x' width={9} height={9} />
              <p className={`initial font-bold ${activeBanner === 'replay-x' ? 'text-black' : 'text-zinc-300'}`}>{t('banner.buttons.replay_x')}</p>
            </button>
          </div>

          <div className='relative flex flex-nowrap gap-2 pt-5'>
            <Image src='/assets/images/banner/thumbnail-fortnite.webp' alt='Thumbnail Fortnite' width={148} height={196} />
            <Image src='/assets/images/banner/thumbnail-csgo.webp' alt='Thumbnail CSGO' width={148} height={196} />
            <Image src='/assets/images/banner/thumbnail-albion.webp' alt='Thumbnail Albion' width={148} height={196} />
            <Image src='/assets/images/banner/thumbnail-dota.webp' alt='Thumbnail Dota 2' width={148} height={196} />
            <Image src='/assets/images/banner/thumbnail-pubg.webp' alt='Thumbnail PUBG' width={148} height={196} />
            <Image src='/assets/images/banner/thumbnail-halo.webp' alt='Thumbnail Halo' width={148} height={196} />
          </div>
        </div>
      </div>
    </div>
  );
}
